<template>
  <el-dropdown div class="iris-select" trigger="click" @command="handleCommand">
    <div class="el-dropdown-link">
      <span>{{ showLabel }}</span>
      <i class="el-icon-arrow-right el-icon--right"></i>
    </div>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item v-for="item in list" :key="item.key" :command="item">{{ item.value }}</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
      default() {
        return [];
      }
    },
    defaultActived: {
      type: [String, Number],
      default: ""
    }
  },
  computed: {
    showLabel() {
      let obj = this.list.find(item => this.actived === item.key) || {};
      return obj.value || "";
    }
  },
  watch: {
    defaultActived: {
      immediate: true,
      handler(val) {
        this.actived = val;
      }
    }
  },
  data() {
    return {
      actived: ""
    };
  },
  methods: {
    handleCommand(item) {
      console.log(item);
      this.actived = item.key;
      this.$emit("change", item, item.key);
    }
  }
};
</script>
<style lang="less" scoped>
.iris-select {
  .el-dropdown-link {
    display: flex;
    align-items: center;
    height: 58px;
    padding: 0 26px 0 18px;
    font-size: 17px;
    color: #333;
    border: 1px solid #921d22; /*no*/
    border-radius: 29px;
    span {
      padding-right: 15px;
      flex: 1;
    }
  }
}
</style>
