<template>
  <div class="diamond-order">
    <div class="banner">
      <img src="../../assets/images/diamond-banner.png" alt />
    </div>
    <div class="container">
      <div class="content">
        <div class="tag">纪念裸钻</div>
        <div class="left">
          <div class="img">
            <img :src="imgtop" alt />
          </div>
          <div class="swiper-box">
            <swiper class="swiper" :options="swiperOption">
              <swiper-slide class="swiper-slide" v-for="(item, index) in wareList" :key="index">
                <div class="swiper-content" >
                  <img :src="imgtbot" alt="" @click="imgtop = imgtbot"/>
                </div>
              </swiper-slide>
            </swiper>
            <!-- 左右箭头 -->
            <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
            <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
          </div>
        </div>
        <div class="right">
          <div class="title">纪念裸钻定制</div>
          <div class="desc">伊莉丝纪念裸钻均呈现精湛切工与璀璨光芒，每一粒均彰显伊莉丝对追求至臻真情的不懈热忱。</div>
          <div class="select">
            <span class="label">大小选择</span>
            <iris-select :defaultActived="searchData.carat" :list="sizeList" @change="sizeChange" />
          </div>
          <div class="select">
            <span class="label">形状选择</span>
            <iris-select
              :defaultActived="searchData.dtype"
              :list="shapeList"
              @change="shapeChange"
            />
          </div>
          <div class="select">
            <span class="label">颜色选择</span>
            <iris-select
              :defaultActived="searchData.color"
              :list="colorList"
              @change="colorChange"
            />
          </div>
          <div class="select">
            <span class="label">证书认证</span>
            <div class="cti">CTI</div>
            <!-- <iris-select :actived="1" :list="certificationList" /> -->
            <img src="../../assets/images/pay/问号.png" class="issue-img" alt="" @mousemove="recheck()" @mouseout="rechecked()">
            <span class="check-label" v-if="isshow">支持IGI、GIA复检</span>
          </div>
          <div class="btn-group">
            <div class="btn" @click="car">添加至购物车</div>
            <div class="btn" @click="order">立即定制</div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      class="dialog"
      :visible.sync="orderPopVisiable"
      width="628px"
      :before-close="handleClose"
    >
      <div class="title">纪念裸钻定制</div>
      <div class="ware">
        <img src="/img/ware6.png" alt />
        <div class="info">
          <div class="name">纪念裸钻</div>
          <div class="price">{{orderDiamon.price|toRMB}}</div>
        </div>
      </div>
      <div class="select">
        <div>
          <span class="label">大小选择：</span>
          <span class="value">{{orderDiamon.caratName}}</span>
        </div>
        <div>
          <span class="label">形状选择：</span>
          <span class="value">{{orderDiamon.dtypeName}}</span>
        </div>
        <div>
          <span class="label">颜色选择：</span>
          <span class="value">{{orderDiamon.colorName}}</span>
        </div>
        <div>
          <span class="label">证书认证：</span>
          <span class="value">CTI</span>
        </div>
      </div>
      <div class="btn" @click="confirm">确认</div>
    </el-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
import irisSelect from "../../components/irisSelect.vue";
import { GET_DIAMON_PARAM, GET_DIAMON_PRICE } from "../../service/other";
import { ORDER_COINFO } from "../../service/shoppingCar";
import {coInfo,addCustomBuyCar} from '../../service/api'

export default {
  name: "DiamondOrder",
  components: { irisSelect },
  data() {
    return {
      orderPopVisiable: false,
     imgtop:require('@/assets/images/ware1.png'),
     imgtbot:require('@/assets/images/ware2.png'),
      sizeList: [],
      shapeList: [],
      colorList: [],
      certificationList: [{ id: 1, value: "CTI" }],
      wareList: [{}, {}, {}, {}, {}],
      swiperOption: {
        //设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        slidesPerView: "auto",
        spaceBetween: 10
      },
      searchData: {
        carat: "",
        dtype: "",
        color: ""
      },
      orderDiamon: {
        price: 0,
        colorName: "",
        scaratNameize: "", 
        dtypeName: ""
      },
      isshow:false
    };
  },
  computed: {},
  created() {
    this.getDiamonPramas();
  },
  mounted(){
  },
  methods: {
    img(){
      console.log(1)
    },
    recheck(){
      this.isshow = true
    },
    rechecked(){
      this.isshow = false
    },
    getDiamonPramas() {
      GET_DIAMON_PARAM().then(res => {
        console.log(res);
        if (res.status === 200) {
          this.sizeList = res.attachment.ct.map(item => {
            return { key: parseFloat(item.value), value: item.value };
          });
          this.shapeList = res.attachment.shape;
          this.colorList = res.attachment.color;
          this.searchData = {
            carat: this.sizeList[0].key,
            dtype: this.shapeList[0].key,
            color: this.colorList[0].key
          };
          Object.assign(this.orderDiamon, {
            caratName: this.sizeList[0].value,
            dtypeName: this.shapeList[0].value,
            colorName: this.colorList[0].value
          });
        }
      });
    },
    sizeChange(item) {
      this.searchData.carat = item.key;
      this.orderDiamon.caratName = item.value;
    },
    shapeChange(item) {
      this.searchData.dtype = item.key;
      this.orderDiamon.dtypeName = item.value;
    },
    colorChange(item) {
      this.searchData.color = item.key;
      this.orderDiamon.colorName = item.value;
    },


    addCar() {
      let propertis = {
        products: [

        ]
    }
    propertis.products.push({
        color: this.searchData.color,
        carat: this.searchData.carat,
        shape: this.searchData.dtype,
        flag: 1,
        pnum: 1
    })
     coInfo({uid:this.$store.getters['user/getUid'], propertis: JSON.stringify(propertis), f: 2 }).then(res => {
       console.log(res)
       if(res.status === 200){
         this.$store.dispatch("order/orderInfo", res.attachment);
         this.$router.push({ path: "/confirmOrder" });
       }else{
         return
       }
      
      });
    },



    car(){
        addCustomBuyCar({
          uid:this.$store.getters['user/getUid'],
          color: this.searchData.color,
          carat: this.searchData.carat,
          shape: this.searchData.dtype,
          pnum: 1
        }).then(res=>{
          if(this.$store.getters['user/getToken']){
          if(res.status == 200){
            var offset = $(".spw").offset();
            var addcar = $(this);
            var img = $(".img")
              .find("img")
              .attr("src");
            var flyer = $(
              '<img class="u-flyer" src="' + img + '" style="z-index:99999;border-radius: 50%;width:50px;height:50px">'
            );
            flyer.fly({
              start: {
                left: event.pageX, //开始位置（必填）#fly元素会被设置成position: fixed
                top: event.pageY //开始位置（必填）
              },
              end: {
                left: offset.left + 10, //结束位置（必填）
                top: offset.top + 10, //结束位置（必填）
                width: 0, //结束时宽度
                height: 0 //结束时高度
              },
              onEnd: function() {
                //结束回调
                // $("#msg")
                //   .show()
                //   .animate({ width: "250px" }, 200)
                //   .fadeOut(1000); //提示信息
                // addcar
                //   .css("cursor", "default")
                //   .removeClass("orange")
                //   .unbind("click");
              }
            });
             this.$store.commit("user/SETCAR", res.attachment.total);
            this.$notify({
              title: "成功",
              message: "您已成功添加此商品",
              type: "success"
            });

            
          }else if(res.status == 402){
            
          }}else{
            this.loading();
            this.$router.push({
              path: "/createLogin.html?url=/diamondorder?ids=" + this.ids
            });
          }
        })
    },

     loading() {
      this.$notify.error({
        title: "未登录",
        message: "您还未登录，赶紧去登陆吧",
        offset: 100
      });
      let loading = this.$loading({
        lock: true,
        text: "您还未登录，正在跳转登陆页面...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      setTimeout(() => {
        loading.close();
      }, 300);
    },



    confirm() {
      this.orderPopVisiable = false;
      this.addCar();
    },
    order() {
      this.orderPopVisiable = true;
      GET_DIAMON_PRICE({ ...this.searchData }).then(res => {
        if (res.status === 200) {
          this.orderDiamon.price = res.attachment.price;
        }
        // console.log("this.orderDiamon.price", this.orderDiamon.price);
      });
    },
    handleClose() {
      this.orderPopVisiable = false;
    }
  }
};
</script>

<style lang="less" scoped>
.u-flyer {
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  position: fixed;
  z-index: 9999;
}
.issue-img{
  width: 20px;/* no */
  height: 20px;/* no */
  margin: 0 15px;
}
.diamond-order {
  .banner {
    width: 1596px;
    height: 400px;
    margin: 0 auto 140px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .container {
    width: 1596px;
    height: 898px;
    margin: 0 auto 200px;
    background-image: url("../../assets/images/diamond-order-bg.png");
    background-size: 100% 100%;
    padding-top: 106px;
    .content {
      display: flex;
      width: 1104px;
      height: 684px;
      border-radius: 30px;
      box-shadow: 0px 4px 5px 2px #666;
      padding: 68px 0 0 94px;
      margin: 0 auto;
      background: #fff;
      position: relative;
      .tag {
        position: absolute;
        left: 30px;
        top: 30px;
        width: 98px;
        line-height: 40px;
        text-align: center;
        color: #fff;
        background: #333;
        font-size: 16px;
        border-radius: 20px;
      }
      .left {
        width: 404px;
      }
      .img {
        width: 406px;
        height: 406px;
        margin-inline: 40px;
        overflow: hidden;
        margin-bottom: 40px;
        margin: 20px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .swiper-box {
        position: relative;
        margin-left: 20px;
      }
      .swiper {
        width: 340px;
      }
      .swiper-slide {
        width: 94px;
      }
      .swiper-content {
        width: 94px;
        height: 96px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .right {
        width: 518px;
        margin-left: 86px;
        .title {
          padding-right: 80px;
          font-size: 50px;
          color: #333;
          margin-bottom: 30px;
        }
        .desc {
          padding-right: 80px;
          line-height: 28px;
          font-size: 18px;
          color: #333;
          margin-bottom: 25px;
        }
        .select {
          display: flex;
          align-items: center;
          margin-bottom: 16px;
          .label {
            font-size: 16px;
            color: #666;
            margin-right: 22px;
          }
          i {
            color: #666;
            font-size: 22px;
            margin-left: 16px;
          }
          .check-label {
            // width: 178px;
            padding: 0 30px;
            height: 36px;
            line-height: 36px;
            text-align: center;
            background: #f5f5f5;
            color: #666;
            font-size: 14px;
          }
          .cti {
            height: 58px;
            line-height: 58px;
            text-align: center;
            padding: 0 26px 0 18px;
            font-size: 17px;
            color: #333;
            border: 1px solid #921d22; /*no*/
            border-radius: 29px;
          }
        }
        .btn-group {
          display: flex;
          padding-top: 10px;
          .btn {
            width: 195px;
            line-height: 58px;
            color: #fff;
            background: #921d22;
            font-size: 16px;
            cursor: pointer;
            text-align: center;
            border-radius: 28px;
          }
          .btn:first-child {
            margin-right: 25px;
          }
        }
      }
    }
  }
}
.dialog {
  .title {
    font-size: 30px;
    line-height: 1;
    color: #000;
    margin-bottom: 30px;
  }
  .ware {
    border-top: 1px solid #cfcfcf; /*no*/
    border-bottom: 1px solid #cfcfcf; /*no*/
    padding: 10px 20px;
    display: flex;
    align-items: center;
    img {
      width: 100px;
      height: 100px;
    }
    .info {
      margin-left: 10px;
    }
    .name {
      font-size: 22px;
      line-height: 1;
      color: #000;
      margin-bottom: 18px;
    }
    .price {
      font-size: 16px;
      line-height: 1;
      color: #921d22;
    }
  }
  .select {
    line-height: 37px;
    margin: 10px 0 32px 130px;
    font-size: 17px;
  }
  .btn {
    width: 195px;
    line-height: 58px;
    color: #fff;
    background: #921d22;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    border-radius: 28px;
    margin: 0 auto;
  }
}
</style>
<style lang="less">
.diamond-order {
  .swiper-button-next,
  .swiper-button-prev {
    --swiper-navigation-color: #aaa;
    --swiper-navigation-size: 30px; /* 设置按钮大小 */
    outline: none;
  }
}
.dialog {
  .el-dialog {
    border-radius: 18px;
  }
  .el-dialog__body {
    padding: 20px 60px 100px;
  }
  .el-dialog__headerbtn {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 2px solid #fff; /*no*/
    top: -60px;
    right: -60px;
    font-size: 50px;
    i {
      color: #fff;
    }
  }
  .el-dialog__headerbtn:focus .el-dialog__close,
  .el-dialog__headerbtn:hover .el-dialog__close {
    color: #fff;
  }
}

.swiper-button-next:after{
  margin-left: 40px ;/* no */

}
.swiper-button-prev:after{
  margin-right: 40px;
}
ul{
  width: 130px;
}
.el-popper{
  border-radius: 30px;

}
 .el-dropdown-menu /deep/ .el-dropdown-menu__item{
   color: #666;
   text-align: center;
 }
 .el-dropdown-menu /deep/ .el-dropdown-menu__item:hover{
   color: #000;
   font-size: 16px;
  background: #fff;
  border-radius: 30px;
 }
</style>
